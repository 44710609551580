#header .header1 {
    background: url("../../assets/banner_topo.png") no-repeat center center;
    background-size: cover;
    height: 100vh;
}

#header .header1 .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
}

#header .header1 .container .title {
    font-size: 1.5rem;
    letter-spacing: 10px;
    margin-top: 4rem;
}

#header .header1 .container .title_middle {
    font-size: 2.5rem;
    color: var(--color-title);
    text-transform: uppercase;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    font-family: "Belleza";
    max-width: 700px;
}

#header .header1 .container .title_middle span {
    color: white;
}

#header .header1 .container .text {
    color: white;
    max-width: 600px;
}

#header .header1 .container #ctaForm {
    margin-right: auto;
    width: 30%;
}

#header .header2 {
    background: url("../../assets/header2.png") no-repeat center center;
    background-size: cover;
}

#header .header2 .container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
    padding-bottom: 0;
}

#header .header2 .container .title {
    color: #001820;
}

#header .header2 .container .title:not(:first-child) {
    border-top: 1px solid #A97F5B;
    padding-top: 1rem;
    width: 40%;
    margin: 0 auto;
}

#header .header2 .container .text {
    color: #212121;
}

#header .header2 .container .gallery {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

#header .header2 .container .gallery .item {
    background-color: #D9D9D9;
    border-radius: 5px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
    width: 70%;
    margin: 0 auto;
}

#header .header2 .container .gallery .item .title {
    font-size: 1.5rem;
}

#header .header2 .container #ctaForm {
    margin: 0 auto;
}

#header .header2 .container .bottom {
    background-color: rgba(0, 0, 0, .6);
    text-align: center;
    width: 100vw;
    margin-left: -10vw;
    color: white;
    padding: 2rem 10vw;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #header .header1 {
        background: url("../../assets/mobile/banner_topo.png") no-repeat center center;
        background-size: cover;
        height: 100vh;
    }

    #header .header1 .container .title {
        margin-top: 6rem;
        font-size: 20px;
    }

    #header .header1 .container .title_middle {
        font-size: 37px;
        max-width: 100%;
        text-align: center;
    }

    #header .header1 .container .text {
        justify-self: flex-end;
        max-width: 100%;
        text-align: center;
        margin-top: auto;
        font-size: 16px;
    }

    #header .header1 .container #ctaForm {
        margin: 0 auto;
        width: 60%;
        height: fit-content;
    }

    #header .header2 {
        background: url("../../assets/mobile/header2.png") no-repeat center center;
        background-size: cover;
    }

    #header .header2 .container .title:not(:first-child) {
        width: 70%;
        margin: 0 auto;
    }

    #header .header2 .container .gallery .item {
        width: 90%;
        margin: 0 auto;
    }

    #header .header2 .container .bottom {
        margin-left: -7.5vw;
        padding: 2rem 7.5vw;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #header .header1 .container .title {
        margin-top: 6rem;
        font-size: 10px;
    }

    #header .header1 .container .title_middle {
        font-size: 25px;
    }

    #header .header1 .container .text {
        font-size: 12px;
    }

    #header .header1 .container #ctaForm {
        width: 70%;
        padding: .7rem 0;
    }

    #header .header2 .container .bottom {
        margin-left: -2vw;
        padding: 2rem 2vw;
    }
}