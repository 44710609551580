#footer .footer1 .container {
    display: flex;
    justify-content: center;
}

#footer .footer1 .container figure img {
    width: 20%;
}

#footer .footer2 .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    border-top: 1px solid #272727;
}

#footer .footer2 .container a {
    color: green;
    transition: var(--transition);
}

#footer .footer2 .container a:hover {
    color: darkgreen;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #footer .footer1 .container figure img {
        width: 30%;
    }

    #footer .footer2 .container {
        flex-direction: column;
        gap: .5rem;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    
}