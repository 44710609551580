#menu {
    background-color: rgba(0, 0, 0, .7);
    position: absolute;
    width: 100vw;
}

#menu .container {
    display: flex;
    justify-content: center;
    padding: .5rem 0;
}

#menu .container figure img {
    width: 15%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #menu .container figure img {
        width: 35%;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #menu .container figure img {
        width: 40%;
    }
}