#about {
  background-color: #FAFAFA;
}

#about .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-align: center;
}

#about .container .content img {
  height: 6vh;
  width: .2%;
}

#about .container .text1 {
  font-style: italic;
  font-weight: 400;
}

#about .container .gallery {
  display: flex;
  justify-content: space-evenly;
}

#about .container .gallery .item_title {
  font-weight: 400;
  text-transform: uppercase;
  padding: 1rem 2rem;
  background-color: #CAAB85;
  border-radius: 10px;
  font-size: 1.5rem;
}

#about .container .gallery ul {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

#about .container .gallery ul li {
  display: flex;
  gap: .3rem;
}

#about .container .gallery ul li figure {
  max-width: 4%;
}

#about .container .gallery ul li p {
  text-align: left;
}

#about .container .gallery .left,
#about .container .gallery .right {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#about .container .gallery .right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#about .container .gallery .right .top,
#about .container .gallery .right .bottom {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#about .container #ctaForm {
  margin: 0 auto;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #about .container .content img {
    height: 3vh;
  }

  #about .container .gallery {
    flex-direction: column;
    gap: 2rem;
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {}