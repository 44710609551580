@import url('https://fonts.googleapis.com/css2?family=Belleza&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --color-black: #000;
  --color-white: #fff;
  --color-title: #CDAD86;

  --transition: all 400ms ease-in-out;

  --container-width-lg: 80vw;
  --container-width-md: 85vw;
  --container-width-sm: 96vw;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

::-webkit-scrollbar {
  display: none;
}

body {
  max-width: 100vw;
  color: var(--color-black);
  background-color: var(--color-white);
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
}

/* ==================== GENERAL STYLES ==================== */
.container {
  width: var(--container-width-lg);
  margin: 0 auto;
  padding: 3rem 0;
}

.container a {
  cursor: pointer;
}

.container .title {
  font-size: 2.5rem;
  text-transform: uppercase;
  font-weight: 500;
  font-family: "Belleza";
  color: var(--color-title);
}

.container .subtitle {
  font-size: 2rem;
  text-align: center;
}

.container p {
  font-size: 1rem;
}

.container figure {
  text-align: center;
  width: 100%;
}

.container img {
  width: 100%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
    padding: 2rem 0;
  }

  .container .title {
    font-size: 2.2rem;
    text-align: center;
  }

  .container .subtitle {
    font-size: 1.7rem;
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
    padding: 1rem 0;
  }

  .container .title {
    font-size: 1.8rem;
  }

  .container .subtitle {
    font-size: 1.3rem;
  }
}