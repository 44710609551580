#ctaForm {
    background: linear-gradient(to right, #CDAD86, #CDAA86);
    font-weight: 700;
    padding: .8rem 1.5rem;
    border-radius: 5rem;
}

#ctaForm a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    color: white;
    font-size: 1.1rem;
    text-transform: uppercase;
    font-weight: 500;
}

#ctaForm a figure {
    width: 8%;
}

#ctaForm a figure img {
    width: 100%;
    display: block;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #ctaForm {
        padding: 1rem 3rem;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #ctaForm p {
        font-size: 14px;
    }
}